let items = [
  {
    title: "订单问题",
    icon: "https://xuanzj.xianniuzu.com/alimini/images/help_icon_dingdan.png",
    problems: [
      {
        title: "如何取消订单？",
        value:
          "<label style='color: #000'>如未发货前，</label>需取消订单，请联系人工客服；<br /><label style='color: #000'>如已发货，</label>则无法取消订单；<br /><label style='color: #000'>用户签收时，</label>发现商品存在硬件故障或商品与商品详情页描述不一致可拒签快递，可联系我司客服申请换货或申请退款；因用户自身原因拒签的，用户承担往返运费和一定金额的违约金；<br /><label style='color: #000'>全新设备</label>被激活或其他行为影响商品二次租赁不支持无理由退换货，特殊情况需联系我司人工客服协商处理。",
      },
      {
        title: "租期内可修改租赁时间吗？",
        value:
          "<label style='color: #000'>租赁期内，承租人暂不支持修改租赁时间。</label>租赁期满后，可选择原机买断、归还、续租。",
      },
      {
        title: "手机可以提前买断吗？",
        value:
          "<label style='color: #000'>可以，</label>若您想提前买断，在订单详情页面点击“买断”，支付剩余费用即可，买断价格=签约价-已付租金",
      },
      {
        title: "可以提前归还吗？需要赔付违约金吗？",
        value:
          "<label style='color: #000'>暂不支持提前归还，</label>如一定要提前归还，需赔付违约金。<br /><label style='color: #000'>具体赔偿标准如下：</label><br />1、二手设备违约赔付标准如下：<br />　（1）总租期在6个月及以上的（含6个月），乙方须支付1个月租金作为违约金；<br />　（2）总租期在3个月-6个月以内的（不含3个月及6个月），乙方须支付半个月租金作为违约金；<br />　（3）总租期在3个月以内的（含3个月），乙方须支付7天租金作为违约金。<br />2、全新设备违约赔付标准：无论总租期时长，若乙方提前退还设备，甲方将收取剩余租赁期租金的50%作为违约金。<br />3、定制产品赔付标准：用户须待租赁期满后才能退还，若用户提前退还设备，我司将收取剩余租赁期租金的50%作为违约金。",
      },
      {
        title: "订单到期后设备如何归还？",
        value:
          "可在订单页面点击“我要还机”按钮，获取地址并自行寄出快递后填写快递单号。",
      },
      {
        title: "订单到期后可以买断吗？",
        value:
          "可在订单页面查看订单是否支持买断，如果<label style='color: #000'>有“我要买断”按钮的，</label>用户可随时支付买断尾款完成买断；<br />如果<label style='color: #000'>只有“申请买断”按钮，</label>我司同意后，即可支付买断尾款完成买断；若我司驳回，则不可买断。",
      },
      {
        title: "订单如何续租？",
        value: "订单到期前，可在订单页面点击“我要续租”按钮进行续租。",
      },
      {
        title: "什么情况下由租转售？",
        value:
          "<label style='color: #000'>在租赁期内，商品失窃，报废，或发生其他形式的损坏，</label>商品将由租转售，需进行买断处理；<br /><label style='color: #000'>经过我司工程师检测，不符合归还标准</label>的商品由租转售，需进行买断处理；",
      },
    ],
  },
  {
    title: "支付问题",
    icon: "https://xuanzj.xianniuzu.com/alimini/images/help_icon_zhifu.png",
    problems: [
      {
        title: "取消订单后什么时候退款？",
        value:
          "发货前取消订单，租金将会在1-3个工作日内原路退回（节假日顺延）；<br />发货后，机器需退回我司并检测无问题后，资金将会在1-3个工作日内原路退回（节假日顺延）。",
      },
      {
        title: "实际支付、冻结的押金什么时候退还？",
        value:
          "<label style='color: #000'>订单完结后，押金将会在1-3个工作日内原路退回（节假日顺延）。</label><br />订单完结含义：<br />1、订单已取消，且用户已付清全部相关费用（如违约金等）；<br />2、订单到期，机器归还，经我司检测确认无问题后，且用户已付清全部相关费用（如租金、滞纳金、赔偿金等）；<br />3、用户选择买断机器，且已付清全部相关费用；<br />4、订单到期后，用户续租的，则续租期满后归还或买断；",
      },
      {
        title: "租金的支付方式有哪些？",
        value:
          "<label style='color: #000'>自动扣款和主动还款</label><br /><label style='color: #000'>自动扣款：</label>还款日当天，系统将会发起免密扣款（注：建议将有资金的账户调整到代扣渠道第一位，操作方式：支付宝-设置-支付设置）<br /><label style='color: #000'>主动还款：</label>还款日当天，可主动操作还款，具体操作：我的-我的订单-订单详情页-主动还款",
      },
    ],
  },
  {
    title: "物流问题",
    icon: "https://xuanzj.xianniuzu.com/alimini/images/help_icon_wuliu.png",
    problems: [
      {
        title: "关于运费，物流损坏问题？",
        value:
          "配送运费可通过商品详情页<label style='color: #000'>“配送方式”</label>查看；<br /><label style='color: #000'>“到期赠送”</label>指订单到期后设备归用户所有，无需归还；<br />建议用户使用邮寄设备时<label style='color: #000'>保价寄送；</label><br />因快递公司原因（丢件等）导致未正常收货或损坏，责任<label style='color: #000'>由发货人承担，</label>由发货人联系快递公司协商处理；<br /><label style='color: #000'>用户签收时需当场开箱验收，</label>我司收到归还的设备后会在2个工作日内完成检测（节假日顺延）；",
      },
      {
        title: "什么时候发货？",
        value:
          "审核通过并支付首期费用后商品将会在48小时内发货（节假日除外，另外个别机器延迟情况，以其页面显示发货时间为准），如果用户需要自取，需请提前联系商家客服并在下单时备注自取。",
      },
      {
        title: "租赁时长怎么计算？",
        value:
          "租期从用户<label style='color: #000'>签收当天起计算</label>（以物流信息签收时间为准）",
      },
      {
        title: "签收需要准备什么资料？",
        value: "签收的时候需要<label style='color: #000'>本人签字签收</label>",
      },
    ],
  },
  {
    title: "售后问题",
    icon: "https://xuanzj.xianniuzu.com/alimini/images/help_icon_shouhou.png",
    problems: [
      {
        title: "故障、损坏、丢失造成的损失谁承担？",
        value:
          "租赁设备正常使用所产生的折旧费用由我司承担，人为损坏、碰撞、划痕等问题，租户需承担维修费用。租赁产品到期归还时不符合还机标准条件的，租户需向我司支付相应的赔偿金。<br /><label style='color: #000'>手机类租赁物：</label><br />1、指纹功能异常：指纹模块无法正常读取或无法正常录入的，赔偿金额=签约价*30%，或支付买断价=签约价-已缴纳金；<br />2、机器异常：进水、功能异常、防潮标变色、螺丝、电池触电、机器发霉、摄像头有水珠或水雾，赔偿金额=签约价*50%，或支付买断价买断租赁物；<br />3、配件缺失或损坏：未寄还租赁产品配件或寄还配件非原装，赔偿金额以配件市场价计算；<br />4、iCloud账户无法注销：赔偿金额=签约价*65%，或支付买断价买断租赁物；<br />5、机器弯曲变形：机身与平面参照物形成可见弧度，导致机身存在重大隐患，赔偿金额=签约价*50%，或支付买断价买断租赁物；<br />6、机器功能不正常（无进水和机身弯曲）：无信号、WiFi不正常、通话不正常、触摸失灵等，赔偿金额=签约价*80%，或支付买断价买断租赁物；<br />7、无法正常开机：开机无法进入正常界面的，赔偿金额=签约价*80%，或支付买断价买断租赁物。<br />8、实际租赁物损坏及赔偿金额以工程师检测报价为准；<br /><label style='color: #000'>非手机类租赁物：</label><br />1、需维修或配件丢失损坏：参考市场维修费用、配件价值和商品价值综合判定；<br />2、实际租赁物损坏及赔偿金额以工程师检测报价为准；",
      },
      {
        title: "设备出现故障怎么办？",
        value:
          "设备出现故障时，请第一时间联系我司客服，咨询问题解决方式，切记不可擅自进行维修或拆机。",
      },
      {
        title: "维修费用谁承担？",
        value:
          "因设备硬件自然损耗或自然故障所产生的的费用、及配件和保养费用由我司承担。设备属人为损坏、碰撞、划痕等问题，由用户承担维修费用以及维修期间的租期费用，具体维修费用以我司的检测报告报价为准。",
      },
      {
        title: "维修运费谁承担？",
        value:
          "我们寄出的运费是<label style='color: #000'>“谁寄谁承担”</label>的原则，运费由寄出的一方承担。",
      },
      {
        title: "售后维修时间？",
        value:
          "我司将会在收到机器后7-15个工作日内进行维修，维修完成后将会第一时间给您寄回（法定节假日及周末顺延）",
      },
    ],
  },
  {
    title: "其他问题",
    icon: "https://xuanzj.xianniuzu.com/alimini/images/help_icon_qita.png",
    problems: [
      {
        title: "怎么查看租金价格？",
        value:
          "您可以在商品页面点击“立即租赁”按钮，选择套餐、规格和租期后，系统自动计算租金，租期越长，价格越优惠。",
      },
      {
        title: "可以开发票吗？",
        value:
          "提交订单时，备注处填写开票详细资料和类型，税点由客户承担，未备注的则不开具发票。",
      },
      {
        title: "还机后我的隐私如何保证？",
        value:
          "<label style='color: #000'>归还机器前建议清除个人信息，</label>如忘记清除，也请不用担心，<label style='color: #000'>我司有专业工程师为您清除数据，</label>保障用户隐私安全，请您放心归还。",
      },
      {
        title: "碎屏保障、意外保障等有什么区别？",
        value:
          "我司设置多款增值服务产品供您选择购买，不同的增值服务具体内容可在商品详情页下单时查看对应的服务说明。",
      },
    ],
  },
];
export default {
  items,
};
